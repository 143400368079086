import Vue from 'vue';
import axios from 'axios';
import store from '../store';
import types from '../store/types';
import router from '../helpers/router';

const Api = axios.create({
  baseURL: '/api',
  headers: {
    common: {
      'Content-Type': 'application/json',
    },
  },
  withCredentials: true,
});

/**
 * Disables a spinner if it was previously enabled by this same request
 */
function disableSpinner({ config }) {
  // Disable spinner
  if (config.spinner) {
    store.dispatch(types.actions.SPINNER, false);
  }
}

/**
 * Intercepts all Api requests before being sent
 */
Api.interceptors.request.use((config) => {
  // Enable spinner if enabled
  if (config.spinner) {
    store.dispatch(types.actions.SPINNER, true);
  }
  return config;
});

/**
 * Intercepts all Api server response's before being handled
 */
Api.interceptors.response.use((response) => {
  disableSpinner(response);
  return response;
},
(error) => {
  // Ignore errors other than unauthorized requests
  if (error.response.status !== 401) {
    return new Promise((resolve, reject) => {
      disableSpinner(error);
      reject(error);
    });
  }

  // Redirect user to login page if refresh token was unsuccessful
  if (error.config.url === '/api/token/refresh') {
    disableSpinner(error);
    store.dispatch(types.actions.user.REMOVE);
    router.push({ name: 'Login' });
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  return new Promise((resolve, reject) => {
    const uninterceptedAxios = axios.create();
    const headers = {
      'X-XSRF-REFRESH-TOKEN': Vue.$cookies.get('XSRF-REFRESH-TOKEN'),
    };
    uninterceptedAxios.post('/api/token/refresh', {}, { headers })
      .then(() => {
        uninterceptedAxios.request(error.config)
          .then((response) => {
            disableSpinner(error);
            resolve(response);
          })
          .catch((newRequestError) => {
            disableSpinner(error);
            store.dispatch(types.actions.user.REMOVE);
            reject(newRequestError);
          });
      })
      .catch((refreshTokenError) => {
        disableSpinner(error);
        store.dispatch(types.actions.user.REMOVE);
        router.push({ name: 'Login' });
        reject(refreshTokenError);
      });
  });
});

export default Api;
