/**
 * Holds all available Vue filters
 */

import Vue from 'vue';

function capitalize(value) {
  if (!value) return '';
  const valueStr = value.toString();
  return valueStr.charAt(0).toUpperCase() + valueStr.slice(1);
}

function trimSecondsFromTime(value) {
  if (value.match(/(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)/)) {
    return value.substring(0, value.length - 3);
  }
  return value;
}

export default function setVueFilters() {
  Vue.filter('capitalize', capitalize);
  Vue.filter('trimSecondsFromTime', trimSecondsFromTime);
}
