/**
 * Holds all action types constants for User actions
 */
const PREFIX = 'user';

export default {
  actions: {
    INIT: `${PREFIX}/init`,
    SAVE: `${PREFIX}/saveUser`,
    REMOVE: `${PREFIX}/removeUser`,
    UPDATE_PROFILE: `${PREFIX}/updateUserProfile`,
    UPDATE_PROFILE_PICTURE: `${PREFIX}/updateProfilePicture`,
  },
  getters: {
    CURRENT_USER: `${PREFIX}/getCurrentUser`,
    IS_LOGGED_IN: `${PREFIX}/isLoggedIn`,
  },
};
