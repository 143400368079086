/**
 * Holds all User actions
 */
export default {
  init({ commit }) {
    commit('USER_INIT');
  },
  saveUser({ commit }, user) {
    commit('USER_SAVE', user);
  },
  updateUserProfile({ commit }, profile) {
    commit('USER_PROFILE_SAVE', profile);
  },
  removeUser({ commit }) {
    commit('USER_REMOVE');
  },
  updateProfilePicture({ commit }, picture, originalPicture) {
    commit('USER_PROFILE_PICTURE', picture, originalPicture);
  },
};
