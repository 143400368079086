import user from './modules/user/types';

export default {
  actions: {
    user: user.actions,
    SPINNER: 'spinner',
  },
  getters: {
    user: user.getters,
  },
};
