/**
 * Holds the Lesson state
 */
export default () => ({
  email: null,
  type: null,
  profile: {
    name: null,
    picture: null,
    picture_original: null,
  },
  offers: [],
  packs: [],
  subscriptions: [],
});
