/**
 * Holds all the User data mutation methods
 */

import UserLocalStorage from './UserLocalStorage';

function isDict(v) {
  return typeof v === 'object' && v !== null && !(v instanceof Array) && !(v instanceof Date);
}

function assignUserDataToState(state, user, isToRemove = false) {
  const keys = isToRemove ? Object.keys(state) : Object.keys(user);
  for (let i = 0; i < keys.length; i += 1) {
    const currentKey = keys[i];
    if (isToRemove || !isDict(state[currentKey])) {
      state[currentKey] = user[currentKey];
    } else {
      assignUserDataToState(state[currentKey], user[currentKey], isToRemove);
    }
  }
}

export default {
  USER_INIT(state) {
    const user = UserLocalStorage.get();
    if (!user) { return; }

    assignUserDataToState(state, user);
  },
  USER_SAVE(state, user) {
    if (!user) throw new Error('Cannot save an empty user');

    assignUserDataToState(state, user);
    UserLocalStorage.save(state);
  },
  USER_PROFILE_SAVE(state, profile) {
    if (!profile) throw new Error('Cannot save an empty user profile');

    assignUserDataToState(state, { profile });
    UserLocalStorage.save(state);
  },
  USER_REMOVE(state) {
    assignUserDataToState(state, {}, true);
    UserLocalStorage.remove();
  },
  USER_PROFILE_PICTURE(state, picture, originalPicture) {
    const data = {
      profile: {
        picture,
        original_picture: originalPicture,
      },
    };
    assignUserDataToState(state, data);
    UserLocalStorage.save(state);
  },
};
