<template>
  <footer class="wrapper style3 special">
    <banner-image-overlay :isOnFooter="true"></banner-image-overlay>
    <div class="container">
      <router-link :to="{ name: 'Dashboard' }">
        <img src="/images/Full Logo - White.svg" alt="MySpanishNow logo" loading="lazy">
      </router-link>
      <ul class="copyright">
        <li><router-link :to="{ name: 'PrivacyPolicy' }">Privacy and Policy</router-link></li>
        <li><router-link :to="{ name: 'TermsAndConditions' }">T&Cs</router-link></li>
        <li><router-link :to="{ name: 'CookiesPolicy' }">Cookies Policy</router-link></li>
      </ul>
      <ul class="icons">
        <li>
          <a href="https://www.youtube.com/@LearnWithMyspanishnow" target="_blank">
            <i class="fa-brands fa-youtube"></i>
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/myspanishnow" target="_blank">
            <i class="fa-brands fa-facebook"></i>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/myspanishnow/" target="_blank">
            <i class="fa-brands fa-linkedin"></i>
          </a>
        </li>
        <li>
          <router-link :to="{ name: 'Contact' }" target="_blank">
            <i class="fa-solid fa-envelope"></i>
          </router-link>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
import BannerImageOverlay from '../utils/BannerImageOverlay.vue';

export default {
  name: 'Footer',
  components: {
    BannerImageOverlay,
  },
};
</script>

<style scoped>

img {
  max-width: 200px;
}

footer {
  position: relative;
  padding: 8em 0 6em 0;
  text-align: center;
}

footer .icons i {
  font-size: 2.5em;
}

footer a {
    -moz-transition: color 0.2s ease-in-out;
    -webkit-transition: color 0.2s ease-in-out;
    -ms-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
    color: white;
    text-decoration: none;
}

footer a:active, footer a:hover {
  color: #aaa;
}

footer .icons li {
  padding-right: 2.5em;
}

footer .icons li:last-child {
  padding-right: 0;
}

footer .copyright {
  margin: 0 0;
  margin-bottom: 2em;
  padding: 0;
  text-align: center;
}

footer .copyright li {
  color: white;
  border-left: solid 1px white;
  display: inline-block;
  list-style: none;
  margin-left: 1.5em;
  padding-left: 1.5em;
}

footer .copyright li:first-child {
  border-left: 0;
  margin-left: 0;
  padding-left: 0;
}

@media screen and (max-width: 980px) {
  footer {
    padding: 6em 0 4em 0;
  }
}

@media screen and (max-width: 736px) {
  footer {
    padding: 3em 0 2em 0;
  }

  footer .icons {
    font-size: 1em;
  }
}

@media screen and (max-width: 480px) {
  footer {
    padding: 3em 0 1em 0;
  }

  footer .copyright li {
    border-left: 0;
    display: block;
    margin: 0;
    padding: 0;
  }
}
</style>
