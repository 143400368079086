<template>
  <div class="banner-overlay" :class="{ fullscreen, height, hide }">
    <img src="/images/overlay.png" class="overlay"/>
    <img :src="`/images/${isOnFooter ? 'footer-': ''}banner-1.jpg`" class="laptop" :loading="isOnFooter ? 'lazy' : 'auto'"/>
    <img :src="`/images/${isOnFooter ? 'footer-': ''}banner-mobile-1.jpg`" class="mobile" :loading="isOnFooter ? 'lazy' : 'auto'"/>
  </div>
</template>

<script>
import EventBus from './event-bus';

export default {
  name: 'BannerImageOverlay',
  props: {
    fullscreen: {
      type: Boolean,
      default: false,
    },
    isOnFooter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mobileRatio: 0.666503906,
      laptopRatio: 1.777777778,
      height: false,
      hide: false,
    };
  },
  methods: {
    onWindowResize() {
      const currentWindowRatio = window.innerWidth / window.innerHeight;
      const ratio = window.innerWidth >= 768 ? this.laptopRatio : this.mobileRatio;
      this.height = currentWindowRatio < ratio;
    },
  },
  created() {
    if (this.isOnFooter) {
      EventBus.$on('hide-footer-banner', (state) => {
        this.hide = state && this.isOnFooter;
      });
    }
  },
  mounted() {
    if (this.fullscreen) {
      EventBus.$emit('hide-footer-banner', true);
      this.onWindowResize();
      window.addEventListener('resize', this.onWindowResize);
    }
  },
  beforeDestroy() {
    if (this.fullscreen) {
      EventBus.$emit('hide-footer-banner', false);
      window.removeEventListener('resize', this.onWindowResize);
    }
  },
};
</script>

<style scoped>
  .banner-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: -1;
  }

  .banner-overlay img {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;
  }

  .banner-overlay img.overlay {
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  .banner-overlay.fullscreen img.mobile {
    top: 0 !important;
  }

  .banner-overlay img.mobile {
    top: -300px;
  }

  .banner-overlay img.laptop {
    top: -100px;
  }

  .banner-overlay.fullscreen img.laptop {
    top: 0;
  }

  .about .banner-overlay img.laptop,
  .faqs .banner-overlay img.laptop {
    top: -50px;
  }

  .about .banner-overlay img.mobile,
  .faqs .banner-overlay img.mobile {
    top: -340px;
  }

  .banner-overlay.fullscreen {
    position: fixed;
  }

  .banner-overlay.fullscreen.height img.laptop,
  .banner-overlay.fullscreen.height img.mobile {
    width: auto;
    height: 100%;
  }

  .mobile {
    display: none;
  }

  footer .banner-overlay img {
    width: 100% !important;
    height: auto !important;
    top: 0 !important;
  }

  @media screen and (max-width: 1402px) {
    .home .banner-overlay img.laptop {
      top: 0;
    }
  }

  @media screen and (max-width: 1159px) {
    .banner-overlay img.laptop {
      top: 0;
    }
  }

  @media screen and (max-width: 1071px) and (min-width: 768px) {
    .home .banner-overlay img.laptop {
      width: auto;
      height: 603px;
    }
  }

  @media screen and (max-width: 767px) {
    .laptop {
      display: none;
    }

    .mobile {
      display: block;
    }
  }

  @media screen and (max-width: 605px) {
    .banner-overlay img.mobile {
      top: -250px;
    }

    .about .banner-overlay img.mobile,
    .faqs .banner-overlay img.mobile {
      top: -300px;
    }
  }

  @media screen and (max-width: 535px) {
    .banner-overlay img.mobile {
      top: -200px;
    }
  }

  @media screen and (max-width: 444px) {
    .banner-overlay img.mobile {
      top: -100px;
    }

    .about .banner-overlay img.mobile,
    .faqs .banner-overlay img.mobile {
      top: -190px;
    }
  }

  @media screen and (max-width: 400px) {
    .banner-overlay img.mobile {
      top: 0;
    }

    .about .banner-overlay img.mobile,
    .faqs .banner-overlay img.mobile {
      top: -190px;
    }
  }

  @media screen and (max-width: 381px) {
    .banner-overlay img.mobile {
      top: 0;
    }

    .about .banner-overlay img.mobile,
    .faqs .banner-overlay img.mobile {
      top: -150px;
    }
  }
</style>
