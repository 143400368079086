import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import types from '../store/types';
import UserApi from '../api/User';

Vue.use(VueRouter);

const routes = [
  /* Public routes */
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/public/Home.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/public/Contact.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/public/AboutMe.vue'),
  },
  {
    path: '/faqs',
    name: 'FAQs',
    component: () => import('../views/public/FAQ.vue'),
  },
  {
    path: '/reset-password/confirmation',
    name: 'ResetPasswordConfirmation',
    component: () => import('../views/public/ResetPasswordConfirmation.vue'),
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/public/Blog.vue'),
  },
  {
    path: '/blog/find-the-perfect-tutor',
    name: 'FindThePerfectTutor',
    component: () => import('../views/public/Posts/FindThePerfectTutor.vue'),
  },
  {
    path: '/blog/learn-spanish-with-duolingo',
    name: 'LearnSpanishWithDuolingo',
    component: () => import('../views/public/Posts/LearnSpanishWithDuolingo.vue'),
  },
  {
    path: '/blog/online-lessons-or-face-to-face',
    name: 'OnlineLessonsOrFacetoFace',
    component: () => import('../views/public/Posts/OnlineLessonsOrFacetoFace.vue'),
  },
  {
    path: '/blog/spanish-for-travellers/restaurant',
    name: 'SpanishForTravellersRestaurant',
    component: () => import('../views/public/Posts/SpanishForTravellers/Restaurant.vue'),
  },
  {
    path: '/blog/:permalink+',
    name: 'View Blog Post',
    component: () => import('../views/public/Posts/ViewBlogPost.vue'),
  },
  /* Public routes that redirects to Hub */
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/public/Signin.vue'),
    meta: {
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/public/Signup.vue'),
    meta: {
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/public/ResetPassword.vue'),
    meta: {
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('../views/public/FAQ.vue'),
    meta: {
      title: 'FAQs',
    },
  },
  {
    path: '/account/confirmation',
    name: 'AccountConfirmation',
    component: () => import('../views/public/AccountConfirmation.vue'),
  },
  {
    path: '/cookies-policy',
    name: 'CookiesPolicy',
    component: () => import('../views/public/CookiesPolicy.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../views/public/PrivacyPolicy.vue'),
  },
  {
    path: '/terms-conditions',
    name: 'TermsAndConditions',
    component: () => import('../views/public/TermsAndConditions.vue'),
  },
  /* Private routes */
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/restricted/Dashboard.vue'),
    meta: {
      requiresLogin: true,
    },
  },
  {
    path: '/dashboard/schedule',
    name: 'Schedule lesson',
    component: () => import('../views/restricted/Dashboard.vue'),
    props: {
      showScheduleModal: true,
    },
    meta: {
      requiresLogin: true,
      title: 'Schedule Lesson - Dashboard',
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/restricted/Profile.vue'),
    meta: {
      requiresLogin: true,
    },
  },
  {
    path: '/profile/plans',
    name: 'Payments',
    component: () => import('../views/restricted/Payment.vue'),
    meta: {
      requiresLogin: true,
      title: 'Plans - Profile',
    },
  },
  {
    path: '/profile/plans/subscriptions',
    name: 'Subscriptions Payments',
    component: () => import('../views/restricted/Payment.vue'),
    meta: {
      requiresLogin: true,
      title: 'Subscriptions - Plans - Profile',
    },
  },
  {
    path: '/profile/plans/packs',
    name: 'Packs Payments',
    component: () => import('../views/restricted/Payment.vue'),
    meta: {
      requiresLogin: true,
      title: 'Packs - Plans - Profile',
    },
  },
  {
    path: '/profile/plans/offers',
    name: 'Offers Payments',
    component: () => import('../views/restricted/Payment.vue'),
    meta: {
      requiresLogin: true,
      title: 'Offers - Plans - Profile',
    },
  },
  /* Private Teacher routes */
  {
    path: '/availability',
    name: 'Availability',
    component: () => import('../views/restricted/teacher/Availability.vue'),
    meta: {
      requiresLogin: 'teacher',
    },
  },
  /* Private blogging routes */
  {
    path: '/editor/blog/posts',
    name: 'Blog Editor',
    component: () => import('../views/restricted/blog/ViewAllBlogPosts.vue'),
    meta: {
      requiresLogin: 'editor',
    },
  },
  {
    path: '/editor/blog/posts/new',
    name: 'New Blog Post',
    component: () => import('../views/restricted/blog/EditBlogPost.vue'),
    meta: {
      requiresLogin: 'editor',
    },
  },
  {
    path: '/editor/blog/posts/:permalink+',
    name: 'Edit Blog Post',
    component: () => import('../views/restricted/blog/EditBlogPost.vue'),
    meta: {
      requiresLogin: 'editor',
    },
  },
  /* Page not found */
  {
    path: '*',
    name: 'PageNotFound',
    component: () => import('../views/errors/PageNotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    /**
     * Smoothly scrolls (i.e. if supported otherwise no animation is used) to top of the page
     * if a route changes. However, if the user hit the navigation back button it scrolls
     * to the previous element
     */
    const position = savedPosition || { x: 0, y: 0 };
    position.behavior = 'smooth';
    return position;
  },
});

router.beforeEach((to, from, next) => {
   if (['Home', 'PrivacyPolicy', 'TermsAndConditions', 'CookiesPolicy', 'FAQ', 'View Blog Post', 'FAQs', 'Blog'].includes(to.name)) {
    window.location.href = to.path;
    return;
  }

  if (Object.keys(to.meta).length === 0) {
    if (to.name === 'Logout') {
      UserApi.logout(false);
      store.dispatch(types.actions.user.REMOVE);
      next({ name: 'Home' });
    } else {
      next();
    }
    return;
  }

  const isUserLoggedIn = store.getters[types.getters.user.IS_LOGGED_IN];
  if (to.meta.requiresLogin) {
    if (!isUserLoggedIn) {
      next({ name: 'Login' });
      return;
    }

    if (typeof to.meta.requiresLogin === 'string' || to.meta.requiresLogin instanceof String) {
      const currentUser = store.getters[types.getters.user.CURRENT_USER];
      if (currentUser.type !== to.meta.requiresLogin && (!currentUser.permissions || !currentUser.permissions.includes(to.meta.requiresLogin))) {
        next({ name: 'PageNotFound' });
        return;
      }
    }
  } else if (to.meta.redirectIfLoggedIn) {
    if (isUserLoggedIn) {
      next({ name: 'Dashboard' });
      return;
    }
  }

  next();
});

function camelcaseToTitle(str) {
  return str.replace(/[A-Z]/g, letter => ` ${letter}`);
}

router.afterEach((to) => {
  const app = document.getElementById('app');
  app.className = to.name.toLowerCase();
  // Using nextTick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    const pageTitle = to.meta.title || camelcaseToTitle(to.name);
    document.title = `${pageTitle} - MySpanishNow`;
  });
});

export default router;
