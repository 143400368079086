/**
 * Holds all available Vue methods
 */

import Vue from 'vue';

/**
 * Returns parsed error, otherwise it returns default error message
 */
function parseError(error, defaultErrorMessage) {
  if (!error) { return defaultErrorMessage; }
  if (typeof error === 'string') { return error; }
  if (error.response && error.response.data) {
    if (error.response.data.description) { return error.response.data.description; }
    if (error.response.data.name) { return error.response.data.name; }
    if (error.response.data.detail) { return error.response.data.detail; }
  }
  if (typeof error.error === 'string') { return error.error; }
  if (typeof error.message === 'string') { return error.message; }
  return defaultErrorMessage;
}

/**
 * Used for sorting out an array of items by date (day, hour and minutes only)
 */
function sortByDate(key, subkey, asc) {
  const mode = asc === undefined || asc ? 1 : -1;
  return (a, b) => {
    const aDatetime = key in a ? a[key] : a[subkey];
    const bDatetime = key in b ? b[key] : b[subkey];
    const aDate = Vue.moment(aDatetime, 'DD/MM/YYYY HH:mm');
    const bDate = Vue.moment(bDatetime, 'DD/MM/YYYY HH:mm');
    if (aDate.isValid && bDate.isValid) {
      if (aDate < bDate) {
        return -1 * mode;
      }
      if (aDate > bDate) {
        return 1 * mode;
      }
      return 0;
    }
    return null;
  };
}

/**
 * Retrieves the current browser timezone
 */
function getLocalTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * Retrieves the current season: 1) summer, 2) winter, 3) fall or 4) spring
 *
 * Winter:  22 Dec - 21 Mar
 * Spring:  22 Mar - 21 Jun
 * Summer:  22 Jun - 21 Sep
 * Fall:    22 Sep - 21 Dec
 */
function getCurrentSeason() {
  // It's plus one because January is index 0
  const now = new Date();
  const month = now.getMonth() + 1;

  if (month > 3 && month < 6) {
    return 'spring';
  }

  if (month > 6 && month < 9) {
    return 'summer';
  }

  if (month > 9 && month < 12) {
    return 'fall';
  }

  if (month >= 1 && month < 3) {
    return 'winter';
  }

  const day = now.getDate();
  if (month === 3) {
    return day < 22 ? 'winter' : 'spring';
  }

  if (month === 6) {
    return day < 22 ? 'spring' : 'summer';
  }

  if (month === 9) {
    return day < 22 ? 'summer' : 'fall';
  }

  if (month === 12) {
    return day < 22 ? 'fall' : 'winter';
  }

  console.error('Unable to calculate current season');
}

/**
 * Retrieves the current season emoji
 */
function getCurrentSeasonEmoji() {
  const emojis = {
    'summer': '☀️',
    'spring': '🌼',
    'winter': '⛄',
    'fall':   '🍁',
  };
  return emojis[getCurrentSeason()];
}

/**
 * Retrieves an heart color depending on the color
 */
function getCurrentSeasonHeart() {
  const emojis = {
    'summer': '💛',
    'spring': '💚',
    'winter': '💙',
    'fall':   '🧡',
  };
  return emojis[getCurrentSeason()]; 
}

const vueMethods = {
  parseError,
  sortByDate,
  getLocalTimezone,
  getCurrentSeasonEmoji,
  getCurrentSeasonHeart,
};

export default {
  vueMethods,
  setVueMethods() {
    Vue.mixin({
      methods: vueMethods,
    });
  },
};
