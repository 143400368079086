import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import Vue from 'vue';
import VuejsClipper from 'vuejs-clipper';
import VCalendar from 'v-calendar';
import VueMoment from 'vue-moment';
import moment from 'moment-timezone';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import vueMethods from './helpers/methods';
import setVueFilters from './helpers/filters';
import router from './helpers/router';
import initFacebookSdk from './helpers/facebook';
import store from './store';

// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'; Messes up with own website CSS
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VuejsClipper);
Vue.use(VCalendar);
Vue.use(VueMoment, {
  moment,
});
Vue.use(VueCookies);
Vue.config.productionTip = false;

vueMethods.setVueMethods();
setVueFilters();
initFacebookSdk();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
