<template>
  <div id="app" v-bind:class="{ 'landing': true, loading }">
    <NavBar></NavBar>
    <router-view></router-view>
    <Footer></Footer>
    <cookie-law
      buttonClass="button special"
      buttonText="I Accept"
      theme="base myspanishnow">
      <div slot="message">
        We and third party services use cookies to help personalise your
        experienve in our website, as well as, to improve our content and
        services. By continuing to browse this website, you agree to this
        use. For more information see our
        <router-link :to="{ name: 'CookiesPolicy' }">Cookies Policy</router-link>
      </div>
    </cookie-law>
    <spinner></spinner>
  </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law';
import NavBar from './components/common/navbar/NavBar.vue';
import Footer from './components/common/footer/Footer.vue';
import Spinner from './components/common/utils/Spinner.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    Footer,
    CookieLaw,
    Spinner,
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
};
</script>

<style>
  body {
    padding-top: 0;
  }

  body.modal-open {
    overflow: auto;
  }

  body.loading {
    overflow: hidden;
    height: 100%;
  }

  .Cookie--base.myspanishnow {
    font-size: 13px;
    color: #666;
  }
</style>
