<template>
  <div>
    <FixedNavBar v-if="currentUser.email && (
      $route.path != '/' &&
      $route.path != '/about' &&
      $route.path != '/contact' &&
      $route.path != '/reset-password/confirmation' &&
      $route.path != '/privacy-policy' &&
      $route.path != '/cookies-policy' &&
      $route.path != '/terms-conditions' &&
      $route.path != '/faqs' &&
      $route.path != '/account/confirmation' &&
      $route.path != '/blog' &&
      !$route.path.startsWith('/blog/'))"></FixedNavBar>
    <InvisibleNavBar v-else></InvisibleNavBar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FixedNavBar from './FixedNavBar.vue';
import InvisibleNavBar from './InvisibleNavbar.vue';
import types from '../../../store/types';

export default {
  name: 'NavBar',
  components: {
    FixedNavBar,
    InvisibleNavBar,
  },
  computed: {
    ...mapGetters({
      currentUser: types.getters.user.CURRENT_USER,
    }),
  },
};
</script>

<style scoped>

</style>
