<template>
  <div class="header-container">
    <header id="header" class="alt">
      <router-link :to="{ name: 'Dashboard' }"
                   class="noselect">
        <img src="/images/Full Logo - Pink.svg" alt="MySpanishNow logo" class="logo computer"/>
        <img src="/images/Logo Mark - Pink.svg" alt="MySpanishNow logo" class="logo mobile"/>
      </router-link>
      <nav id="nav">
        <div class="me" @click.stop="toggleMenu">
          <a class="noselect">
            <img :src="currentUser.profile.picture">
            {{ currentUser.profile.name }}
            <i class="fas fa-angle-down" v-bind:class="{ rotate: submenu }"></i>
          </a>
        </div>
        <ul ref="submenu" class="sub-menu" v-show="submenu" @click.stop="toggleMenu">
          <li>
            <router-link :to="{ name: 'Dashboard' }"
                         class="noselect">Dashboard</router-link>
          </li>
          <li v-if="currentUser.type == 'teacher'">
            <router-link :to="{ name: 'Availability' }"
                         class="noselect">Availability</router-link>
          </li>
          <li v-if="currentUser.permissions && currentUser.permissions.includes('editor')">
            <router-link :to="{ name: 'Blog Editor' }"
                         class="noselect">Blog Editor</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Profile' }"
                         class="noselect">Profile</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Logout' }"
                         class="noselect">Logout</router-link>
          </li>
        </ul>
      </nav>
    </header>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from '../utils/event-bus';
import types from '../../../store/types';

export default {
  name: 'FixedNavBar',
  data() {
    return {
      submenu: false,
    };
  },
  methods: {
    toggleMenu() {
      this.submenu = !this.submenu;
      if (this.submenu) {
        document.addEventListener('click', this.toggleMenu);
      } else {
        document.removeEventListener('click', this.toggleMenu);
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: types.getters.user.CURRENT_USER,
    }),
  },
  created() {
    EventBus.$on('edited-name', (name) => {
      this.currentUser.profile.name = name;
    });
  },
};
</script>

<style scoped>
  .header-container {
    height: 3.25em;
    font-size: 1.25em;
    margin-bottom: 25px;
  }

  header#header {
    position: fixed;
    background-color: #fff;
    border-bottom: 1px solid #aaaaaa5e;
    display: block;
    font-size: 100%;
    z-index: 20;
  }

  img.logo {
    max-height: 100%;
    position: absolute;
    margin-left: 25px;
    max-width: 248px;
  }

  img.logo.mobile {
    display: none;
    max-width: 64px;
  }

  header#header .me img {
    width: 35px;
  }

  #header nav {
    right: 0;
    top: 0;
    text-transform: capitalize;
  }

  #header.alt .me {
    padding-left: 25px;
    cursor: pointer;
    padding-right: 2em;
  }

  #header.alt nav .me a:active,
  #header.alt nav .me a:hover,
  #header.alt ul a:hover {
    color: #f32853;
  }

  #header .me a,
  #header ul a {
    color: #f16f8b;
    font-size: 16px;
  }

  #header .me img {
    margin-right: 7px;
    position: relative;
    top: 10px;
    border-radius: 50%;
  }

  #header nav ul li {
    margin-left: 0em;
    width: 100%;
  }

  #header nav ul li a {
    width: 100%;
    padding-left: 2em;
  }

  #header nav ul {
    position: absolute;
    background-color: rgb(255, 255, 255);
    border-bottom: 1px solid rgba(170, 170, 170, 0.37);
    top: 100%;
    margin-top: -1px;
    border-left: 1px solid rgba(170, 170, 170, 0.37);
  }

  header nav i {
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;

    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
     transition-property: transform;

     overflow:hidden;
  }

  header nav i.rotate {
    transform: rotate(180deg);
    -webkit-transform:rotate(180deg);
    -moz-transform:rotate(180deg);
    -o-transform:rotate(180deg);
  }

  @media screen and (max-width: 1680px) {
    img.logo.laptop {
      max-width: 210px;
    }
  }

  @media screen and (max-width: 595px) {
    #header.alt .me {
      padding-right: 16px;
    }

    img.logo.mobile {
      display: block;
      margin-left: 0;
    }

    img.logo.computer {
      display: none;
    }
  }
</style>
