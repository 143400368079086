<template>
  <div class="invisible-navbar">
    <header id="header" class="alt">
      <router-link :to="{ name: 'Dashboard' }"
                   class="noselect">
        <img src="/images/Full Logo - White.svg" alt="MySpanishNow logo" class="logo computer"/>
        <img src="/images/Logo Mark - White.svg" alt="MySpanishNow logo" class="logo mobile"/>
      </router-link>
      <a href="#" class="hidden-lg mobile-menu-button" ref="menuButton"
        @click.stop.prevent="toggleMobileMenu">
        <span></span>
      </a>
      <nav id="nav">
        <ul v-show="show" class="menu">
          <li>
            <router-link :to="{ name: 'Home' }">Home</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'About' }">About</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'FAQs' }">FAQs</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Blog' }">Blog</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Contact' }">Contact</router-link>
          </li>
          <li v-show="currentUser.email">
            <router-link :to="{ name: 'Dashboard' }"
              class="special button small">Dashboard</router-link>
          </li>
          <li v-show="!currentUser.email">
            <router-link :to="{ name: 'Login' }">Login</router-link>
          </li>
          <li v-show="!currentUser.email">
            <router-link :to="{ name: 'Register' }"
              class="special button small">Register</router-link>
          </li>
        </ul>
      </nav>
    </header>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import types from '../../../store/types';

export default {
  name: 'InvisibleNavBar',
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toggleMobileMenu() {
      this.$refs.menuButton.classList.toggle('on');
      this.show = !this.show;
    },
    onWindowResize() {
      if (window.innerWidth <= 980) {
        window.addEventListener('click', this.onMenuLoseFocus);
      } else {
        this.show = true;
        window.removeEventListener('click', this.onMenuLoseFocus);
      }
    },
    onMenuLoseFocus(e) {
      if (e.target.classList.contains('mobile-menu-button')) {
        e.stopPropagation();
        return;
      }

      this.$refs.menuButton.classList.remove('on');
      this.show = false;
    },
  },
  computed: {
    ...mapGetters({
      currentUser: types.getters.user.CURRENT_USER,
    }),
  },
  mounted() {
    this.onWindowResize();
    window.addEventListener('resize', this.onWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
    window.removeEventListener('click', this.onMenuLoseFocus);
  },
};
</script>

<style scoped>
  #header nav ul li .button.special {
    height: 2.2em;
    line-height: 2.2em;
    padding: 0 0.7em;
    font-weight: normal;
  }

  header#header nav {
    text-transform: capitalize;
  }

  img.logo {
    max-height: 100%;
    position: absolute;
    margin-left: 25px;
    top: 2em;
    margin-top: -1px;
    max-width: 212px;
  }

  img.logo.mobile {
    display: none;
    max-width: 65px;
  }

  .mobile-menu-button {
    display: none;
  }

  @media screen and (max-width: 980px) {
    #header {
      display: block;
    }

    img.logo,
    #header > nav {
      top: 15px;
    }

    #header > nav {
      right: 12vw;
      font-size: 0.85em;
    }

    #nav {
      right: 70px !important;
    }

    #nav .menu {
      width: fit-content;
      right: 0px;
      top: 0;
      background-color: white;
      height: fit-content;
      border: 1px solid #48484870;
      border-radius: 4px;
    }

    .mobile-menu-button {
      position: absolute;
      right: 25px;
      top: 25px;
      padding: 0;
      z-index: 10;
      display: block;
      width: 28px;
      height: 28px;
    }

    .mobile-menu-button span:after,
    .mobile-menu-button span:before {
      content: "";
      position: absolute;
      left: 0;
      top: 9px;
    }

    .mobile-menu-button span:after {
      top: 18px;
    }

    .mobile-menu-button span {
      position: relative;
      display: block;
    }

    .mobile-menu-button span,
    .mobile-menu-button span:after,
    .mobile-menu-button span:before {
      width: 100%;
      height: 2px;
      background-color: #fff;
      transition: all 0.3s;
      backface-visibility: hidden;
      border-radius: 2px;
    }

    .mobile-menu-button.on span {
      background-color: transparent;
    }

    .mobile-menu-button.on span:before {
      transform: rotate(45deg) translate(-1px, 0px);
    }

    .mobile-menu-button.on span:after {
      transform: rotate(-45deg) translate(6px, -7px);
    }

    #header nav ul li {
      color: #bbb;
      display: block;
      padding-left: 30px;
      padding-right: 30px;
      margin-left: 0;
    }

    #header.alt nav a {
      color: #666;
    }

    #header nav ul li .button.special {
      padding: 0 !important;
      background-color: transparent;
      color: #666 !important;
    }
  }

  @media screen and (max-width: 595px) {
    img.logo.mobile {
      display: block;
      margin-left: 0;
      top: 5px;
    }

    img.logo.computer {
      display: none;
    }

    #header > nav {
      top: 0;
    }

    #header > nav .menu {
      margin-top: 15px;
    }
  }
</style>
