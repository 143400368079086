/**
 * Holds all the user API requests
 */
import Api from './Api';
import store from '../store';
import types from '../store/types';

export default {
  signin(email, password, spinner = true) {
    const data = {
      email,
      password,
    };
    return Api.post('signin', data, { spinner });
  },
  signup(email, password, spinner = true) {
    const data = {
      email,
      password,
    };
    return Api.post('student/signup', data, { spinner });
  },
  getProfile() {
    const user = store.getters[types.getters.user.CURRENT_USER];
    return Api.get(`${user.type}/profile`);
  },
  updateProfile(profile, spinner = true) {
    return Api.put('account', { profile }, { spinner });
  },
  deleteProfilePicture() {
    return Api.delete('profile/picture');
  },
  updateProfilePicture(data) {
    const configs = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    return Api.post('profile/picture', data, configs);
  },
  accountConfirmation(token, spinner = true) {
    return Api.post('account/confirmation', { token }, { spinner });
  },
  resetPassword(email = undefined, spinner = true) {
    const data = email ? { email } : null;
    return Api.post('reset-password', data, { spinner });
  },
  resetPasswordConfirmation(newPassword, token, spinner = true) {
    const data = {
      new_password: newPassword,
      token,
    };
    return Api.put('reset-password/confirmation', data, { spinner });
  },
  resendActivationEmail(email, spinner = true) {
    return Api.post('account/confirmation/resend', { email }, { spinner });
  },
  logout(spinner = true) {
    return Api.delete('token/delete', null, { spinner });
  },
};
