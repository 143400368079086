/**
 * Holds all the required functionalities to save/get/delete an user from the LocalStorage
 */
class UserLocalStorage {
  constructor() {
    this.TABLE = 'user';
  }

  /**
   * Removes an user from the LocalStorage
   */
  remove() {
    window.localStorage.removeItem(this.TABLE);
  }

  /**
   * Saves an user (dictionary) to the LocalStorage.
   *
   * Throws an error if nothing is passed as argument.
   *
   * WARNS: Doesn't check for user structure before saving it
   */
  save(user) {
    if (!user) throw new Error('Missing user');

    window.localStorage.setItem(this.TABLE, JSON.stringify(user));
    return user;
  }

  /**
   * Retrieves the current stored user if any using (JSON.parse) or returns false if none
   */
  get() {
    let user = window.localStorage.getItem(this.TABLE);
    if (!user) { return false; }
    try {
      user = JSON.parse(user);
      if (!user.profile) {
        user.profile = {};
      }
      return user;
    } catch {
      this.remove();
    }
    return false;
  }
}

export default new UserLocalStorage();
