var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.currentUser.email && (
    _vm.$route.path != '/' &&
    _vm.$route.path != '/about' &&
    _vm.$route.path != '/contact' &&
    _vm.$route.path != '/reset-password/confirmation' &&
    _vm.$route.path != '/privacy-policy' &&
    _vm.$route.path != '/cookies-policy' &&
    _vm.$route.path != '/terms-conditions' &&
    _vm.$route.path != '/faqs' &&
    _vm.$route.path != '/account/confirmation' &&
    _vm.$route.path != '/blog' &&
    !_vm.$route.path.startsWith('/blog/')))?_c('FixedNavBar'):_c('InvisibleNavBar')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }