/**
 * Contains Facebook SDK code
 */

function fbAsyncInit() {
  if (!window.FB) { return; }
  window.FB.init({
    xfbml: true,
    version: 'v11.0',
  });
}

export default function initFacebookSdk() {
  window.fbAsyncInit = fbAsyncInit;
}
