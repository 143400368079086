/**
 * Holds all getters from an User
 */
export default {
  getCurrentUser(state) {
    return state;
  },
  isLoggedIn(state) {
    return state.email;
  },
};
